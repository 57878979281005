import { useState, useEffect } from "react";
import "./App.css";
import { db, app } from "./firebase-config";
import { format } from 'date-fns'
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from 'firebase/auth';

function App() {
  const [newName, setNewName] = useState("");
  const [newMarks, setNewMarks] = useState(0);

  const [users, setUsers] = useState([]);
  const usersCollectionRef = collection(db, "temperature");

  const createUser = async () => {
    await addDoc(usersCollectionRef, { name: newName, marks: Number(newMarks) });
    getUsers();
  };

  const updateUser = async (id, marks) => {
    const userDoc = doc(db, "users", id);
    const newFields = { marks: marks + 1 };
    await updateDoc(userDoc, newFields);
    getUsers();
  };

  const deleteUser = async (id) => {
    const userDoc = doc(db, "users", id);
    await deleteDoc(userDoc);
    getUsers();
  };

  const getUsers = async () => {
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };



  useEffect(() => {
    signInWithEmailAndPassword(getAuth(app), 'microservice@globaliot.com.br', 'Gb3075@8076Ms')
      .then(async (userCredential) => {
        setInterval(() => {
          getUsers();
        }, []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="App" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}>
      <div style={{ width: '40%' }}>
        {users.map((user) => (
          <div key={user.id} style={{
            marginTop: 10,
            padding: 20,
            borderRadius: 9,
            borderWidth: 1,
            borderBlockColor: '#ccb398',
            backgroundColor: '#f0ead6'
          }}>
            <h1>Equipamento: {user.device}</h1>
            <h1>Data/Hora: {format(new Date(user.tStamp), "dd/MM/yyyy HH:mm:ss")}</h1>
            <h1>Sensor 1: {user.tOne.toFixed(1)} ºC</h1>
            {user.tTwo !== 255 && <h1>Sensor 2: {user.tTwo.toFixed(1)} ºC</h1>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;