import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAt5F3_OVNOx6tySQv_WKHB2ANcDm5YbrE",
    authDomain: "globaliot-13ce3.firebaseapp.com",
    projectId: "globaliot-13ce3",
    storageBucket: "globaliot-13ce3.appspot.com",
    messagingSenderId: "991980961714",
    appId: "1:991980961714:web:aa8e2332b57e84eed32eab",
    measurementId: "G-6GXE6DFNYM"
};

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)